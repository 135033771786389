<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="firstName"
          label="First Name *"
          placeholder="Jane"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="clientDemoInfo.firstName"
          >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="lastName"
          label="Lastname *"
          placeholder="Doe"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="clientDemoInfo.lastName">
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="email"
          label="Email *"
          placeholder="example@example.com"
          :rules="[rules.required, rules.email]"
          @input="debouncedInputHandler"
          v-model="clientDemoInfo.email"
          >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="phone"
          label="Phone *"
          placeholder="780-115-1337"
          :rules="[rules.required, rules.phoneLength]"
          @input="debouncedInputHandler"
          v-model="clientDemoInfo.phoneNumber"
          v-mask="'1-###-###-####'"
          counter="14">
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="homeAddressOne"
          label="Home Address Line 1 *"
          placeholder="123 Fake Lane"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="clientDemoInfo.homeAddressOne"
          >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="city"
          label="City *"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="clientDemoInfo.city">
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="homeAddressTwo"
          label="Home Address Line 2"
          @input="debouncedInputHandler"
          v-model="clientDemoInfo.homeAddressTwo">
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-select
          ref="province"
          label="Province"
          @input="debouncedInputHandler"
          v-model="clientDemoInfo.province"
          :items="provinceOptions"
        ></v-select>
      </v-col>
      <!-- <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="dob"
          readonly
          label="Select your birthdate"
          prepend-icon="mdi-cake-variant"
          @click:prepend="setBirthdayModalOpenState(isBirthdayModalVisible ? false : true)"
          @focus="setBirthdayModalOpenState(isBirthdayModalVisible ? false : true)"
          v-model="formattedDOB">
        </v-text-field>
      </v-col> -->
      <v-col
        cols="12"
        md="6">
        <v-radio-group
          v-model="selected"
          inline
        >
          <template v-slot:label>
            <div>Your preferred contact method :</div>
          </template>
          <v-radio
            label="Email"
            value="radio-1"
          ></v-radio>
          <v-radio
            label="Phone"
            value="radio-2"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6">
        <v-checkbox v-model="has_spouse" label="Please check if you have a spouse"></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-checkbox v-model="has_dependants"  label="Please check if you have any dependants"></v-checkbox>
        <v-text-field v-if="has_dependants"
          ref="city"
          label="# of dependants">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6">
        <v-card v-if="has_spouse">
          <v-card-item>
            <v-card-title class="text-left">Spouse Demographic Info</v-card-title>
          </v-card-item>

          <v-card-item>
            <v-text-field
              ref="dob"
              readonly
              v-model="formattedDOB1"
              label="Select your spouses birthdate"
              prepend-icon="mdi-cake-variant"
              @click:prepend="setBirthdayModal1OpenState(isBirthdayModal1Visible ? false : true)"
              @focus="setBirthdayModal1OpenState(isBirthdayModal1Visible ? false : true)"
              >
            </v-text-field>
          </v-card-item>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-card v-if="has_dependants">
          <v-card-item>
            <v-card-title class="text-left">Dependant #1 Demographic Info</v-card-title>
          </v-card-item>

          <v-card-item>
            <v-text-field
              ref="dob"
              readonly
              v-model="formattedDOB2"
              label="Select your dependant's birth date"
              prepend-icon="mdi-cake-variant"
              @click:prepend="setBirthdayModal2OpenState(isBirthdayModal2Visible ? false : true)"
              @focus="setBirthdayModal2OpenState(isBirthdayModal2Visible ? false : true)"
              >
            </v-text-field>
          </v-card-item>
        </v-card>
      </v-col>
      <!-- Spouse card -->
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6">
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-card v-if="has_dependants">
          <v-card-item>
            <v-card-title class="text-left">Dependant #2 Demographic Info</v-card-title>
          </v-card-item>

          <v-card-item>
            <v-text-field
              ref="dob"
              readonly
              label="Select your dependant's birth date"
              v-model="formattedDOB3"
              prepend-icon="mdi-cake-variant"
              @click:prepend="setBirthdayModal3OpenState(isBirthdayModal3Visible ? false : true)"
              @focus="setBirthdayModal3OpenState(isBirthdayModal3Visible ? false : true)"
              >
            </v-text-field>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog width="375px" v-model="isBirthdayModal1Visible">
      <template v-slot:default="{ isActive }">
        <v-card>
          <v-date-picker
            width="375px"

            label="Date of Birth *"
            v-model="clientDemoInfo.spousedob">
          </v-date-picker>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text="Close"
              @click="isActive.value = false"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="375px" v-model="isBirthdayModal2Visible">
      <template v-slot:default="{ isActive }">
        <v-card>
          <v-date-picker
            width="375px"

            label="Date of Birth *"
            v-model="clientDemoInfo.dependant1dob">
          </v-date-picker>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text="Close"
              @click="isActive.value = false"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="375px" v-model="isBirthdayModal3Visible">
      <template v-slot:default="{ isActive }">
        <v-card>
          <v-date-picker
            width="375px"

            label="Date of Birth *"
            v-model="clientDemoInfo.dependant2dob">
          </v-date-picker>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text="Close"
              @click="isActive.value = false"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { useCommonEventHandler } from '../../utilities/useCommonEventHandler.js';

import debounce from 'lodash';
import { mask } from 'vue-the-mask'
//import { nextTick } from 'vue';

export default {
    name: 'ClientDemoInfo',
    directives: {
      mask
    },
    props: {
      clientDemoInfoProp: Object
    },
    data: () => ({
      // clientDemoInfo: {
      //   firstName: null,
      //   lastName: null,
      //   email: null,
      //   phoneNumber: null,
      //   homeAddressOne: null,
      //   homeAddressTwo: null,
      //   city: null,
      //   province: null,
      //   postalCode: null,
      //   dob: null
      // },
      has_spouse: false,
      has_dependants: false,
      selected: "radio-1",
      isBirthdayModal1Visible: false,
      isBirthdayModal2Visible: false,
      isBirthdayModal3Visible: false,
      clientDemoInfo: null,
      provinceOptions: [
        {value: null, title: "Please select an option" },
        {value: "Alberta", title: "Alberta"},
        {value: "British Columbia", title: "British Columbia"},
        {value: "Manitoba", title: "Manitoba"},
        {value: "New Brunswick", title: "New Brunswick"},
        {value: "Northwest Territories", title: "Northwest Territories"},
        {value: "Nova Scotia", title: "Nova Scotia"},
        {value: "Nunavut", title: "Nunavut"},
        {value: "Ontario", title: "Ontario"},
        {value: "Prince Edward Island", title: "Prince Edward Island"},
        {value: "Quebec", title: "Quebec"},
        {value: "Saskatchewan", title: "Saskatchewan"},
        {value: "Yukon", title: "Yukon"}
      ],
      rules: {
        required: value => !!value || 'Field is required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        phoneLength: value => value.length == 14 || 'Invalid phone number.',
        postalCodeLength: value => value.length == 7 || 'Invalid postal code'
      },
    }),
    methods: {
      setBirthdayModal1OpenState(state){
        this.isBirthdayModal1Visible = state;
      },
      setBirthdayModal2OpenState(state){
        this.isBirthdayModal2Visible = state;
      },
      setBirthdayModal3OpenState(state){
        this.isBirthdayModal3Visible = state;
      },
      formatPhoneNumber() {
        //Limit length to max character length for phone number
        if(this.clientDemoInfo.phoneNumber.length > 14){
          this.clientDemoInfo.phoneNumber = this.clientDemoInfo.phoneNumber.substring(0,13)
        }

        // Remove non-numeric characters
        this.clientDemoInfo.phoneNumber = this.clientDemoInfo.phoneNumber.replace(/\D/g, '');
        
        //Format to target pattern
        this.clientDemoInfo.phoneNumber = this.clientDemoInfo.phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '1-$1-$2-$3');
      },
      handleInput() {
        this.isValid();
        useCommonEventHandler(this.clientDemoInfo);
      },
      debouncedInputHandler() {
        this.isValid();
        debounce(this.handleInput, 500)
      },
      //TO DO : Needs to be updated
      isValid(){
        this.$emit('is-valid', true);
        return true;
        // let self = this;

        // //Force validation checks and appropriate form states
        // // this.$refs.firstName.hasError = this.$refs.firstName.validate();
        // // this.$refs.lastName.hasError = this.$refs.lastName.validate();
        // // this.$refs.email.hasError = this.$refs.email.validate();
        // // this.$refs.phone.hasError = this.$refs.phone.validate();
        // // this.$refs.homeAddressOne.hasError = this.$refs.homeAddressOne.validate();
        // // this.$refs.city.hasError = this.$refs.city.validate();
        // // this.$refs.province.hasError = this.$refs.province.validate();
        // // this.$refs.dob.hasError = this.$refs.dob.validate();
        // // this.$refs.postalCode.hasError = this.$refs.postalCode.validate();

        // //Check all of the bound values for validity
        // nextTick(function(){
        //   nextTick(function(){
        //     let firstNameInput = self.$refs.firstName.errorMessages.length == 0 && self.$refs.firstName.value != '';
        //     let lastNameInput = self.$refs.lastName.errorMessages.length == 0 && self.$refs.lastName.value != '';
        //     let emailInput = self.$refs.email.errorMessages.length == 0 && self.$refs.email.value != '';
        //     let phoneInput = self.$refs.phone.errorMessages.length == 0 && self.$refs.phone.value != '';
        //     let homeAddressOneInput = self.$refs.homeAddressOne.errorMessages.length == 0 && self.$refs.homeAddressOne.value != '';
        //     let cityInput = self.$refs.city.errorMessages.length == 0 && self.$refs.city.value != '';
        //     let provinceInput = self.$refs.province.errorMessages.length == 0 && self.$refs.province.value != '';
        //     let dobInput = self.$refs.dob.errorMessages.length == 0 && self.$refs.dob.value != '';
        //     let postalCodeInput = self.$refs.postalCode.errorMessages.length == 0 && self.$refs.postalCode.value != '';

        //     self.$emit('is-valid', firstNameInput && lastNameInput && emailInput && phoneInput && homeAddressOneInput
        //                         && cityInput && provinceInput && dobInput && postalCodeInput);
        //     });
        // });
      }
    },
    computed: {
      formattedDOB1: function(){
        if(this.clientDemoInfo.spousedob == null){
          return null;
        }else{
          let dob = new Date(this.clientDemoInfo.spousedob);
          return dob.toLocaleDateString('en-US');
        }
      },
      formattedDOB2: function(){
        if(this.clientDemoInfo.dependant1dob == null){
          return null;
        }else{
          let dob = new Date(this.clientDemoInfo.dependant1dob);
          return dob.toLocaleDateString('en-US');
        }
      },
      formattedDOB3: function(){
        if(this.clientDemoInfo.dependant2dob == null){
          return null;
        }else{
          let dob = new Date(this.clientDemoInfo.dependant2dob);
          return dob.toLocaleDateString('en-US');
        }
      }
    },
    setup() {
      const { commonEventHandler } = useCommonEventHandler();
      return { commonEventHandler };
    },
    created() {
      this.clientDemoInfo = this.clientDemoInfoProp;
    },
    mounted() {
      this.isValid();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
