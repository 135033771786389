<template>
  <v-container>
    <v-row>
      <v-col
        cols="12">
        <v-combobox
          ref="investmentObjective"
          @update:modelValue="debouncedInputHandler"
          auto-select-first
          label="What is your primary investment objective for this portfolio?"
          :items="investmentObjectiveOptions"
          v-model="clientRiskProfile.investmentObjective">
        </v-combobox>
      </v-col>
      <v-col
        cols="12">
        <v-combobox
          ref="timeHorizon"
          @update:modelValue="debouncedInputHandler"
          auto-select-first
          label="What is your time horizon for the investments?"
          :items="timeHorizonOptions"
          v-model="clientRiskProfile.timeHorizon">
        </v-combobox>
      </v-col>
      <v-col
        cols="12">
        <v-combobox
          ref="riskTolerance"
          @update:modelValue="debouncedInputHandler"
          auto-select-first
          label="How would you describe your overall risk tolerance when it comes to investing?"
          :items="riskToleranceOptions"
          v-model="clientRiskProfile.riskTolerance">
        </v-combobox>
      </v-col>
      <v-col
        cols="12">
        <v-combobox
          ref="courage"
          @update:modelValue="debouncedInputHandler"
          auto-select-first
          label="How much of a temporary decline in your portfolio could you tolerate within a one-year period?"
          :items="courageOptions"
          v-model="clientRiskProfile.courage">
        </v-combobox>
      </v-col>
      <v-col
        cols="12">
        <v-combobox
          ref="investKnowledge"
          @update:modelValue="debouncedInputHandler"
          auto-select-first
          label="What is your level of investment knowledge?"
          :items="investKnowledgeOptions">
        </v-combobox>
      </v-col>
      <v-col
        cols="12">
        <v-combobox
          ref="investKnowledge"
          @update:modelValue="debouncedInputHandler"
          auto-select-first
          label="What is your total estimated amount of investable income?"
          :items="estimatedInvestable"
          v-model="clientRiskProfile.investmentKnowledge">
        </v-combobox>
      </v-col>
      <v-col cols="12"
        md="6">
        <v-text-field
            ref="dob"
            readonly
            label="Select your birthdate"
            prepend-icon="mdi-cake-variant"
            >
          </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useCommonEventHandler } from '../../utilities/useCommonEventHandler.js';
import debounce from 'lodash';
import { nextTick } from 'vue';


export default {
    name: 'ClientRiskProfile',
    props: {
      clientRiskProfileProp: Object
    },
    data: () => ({
      // clientRiskProfile: {
      //   investmentObjective: null,
      //   timeHorizon: null,
      //   riskTolerance: null,
      //   courage: null,
      //   investmentKnowledge: null
      // },
      clientRiskProfile: null,
      investmentObjectiveOptions: [
        { value:1 , title:'Capital Preservation' },
        { value:2 , title:'Tax-Efficient Income' },
        { value:3 , title:'Income & Growth' },
        { value:4 , title:'Capital Growth' }
      ],
      timeHorizonOptions: [
        { value:1 , title:'Less than 3 years' },
        { value:2 , title:'3 - 5 Years' },
        { value:3 , title:'5 - 10 Years' },
        { value:4 , title:'10+ Years' }
      ],
      riskToleranceOptions: [
        { value:1 , title:"Very Low" },
        { value:2 , title:"Low" },
        { value:3 , title:"Medium" },
        { value:4 , title:"High" }
      ],
      courageOptions: [
        { value:1 , title:"0-5%" },
        { value:2 , title:"6-10%" },
        { value:3 , title:"11-15%" },
        { value:4 , title:"15% or more" }
      ],
      investKnowledgeOptions: [
        { value:1 , title:"Beginner / Novice" },
        { value:2 , title:"Limited / Fair" },
        { value:3 , title:"Intermediate / Good" },
        { value:4 , title:"Advanced / Sophisticated" }
      ],
      estimatedInvestable: [
        { value:1 , title:"$0 - $250,000" },
        { value:2 , title:"$250,001 - $1,000,000" },
        { value:3 , title:"$1,000,001+" }
      ],
      rules: {
        required: value => !!value || 'Field is required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        phoneLength: value => value.length <= 14
      },

    }),
    methods: {
      handleInput() {
        useCommonEventHandler(this.clientRiskProfile);
      },
      debouncedInputHandler() {
        this.isValid();
        debounce(this.handleInput, 500)
      },
      isValid(){
        let self = this;
        //I dont know why I have to do this but there is a race condition IRT the resolution of the error messages array which causes even next tick to fail
        //and requires a double next tick to get a valid validation state
        nextTick(function(){
          nextTick(function(){
            //Check all of the bound values for validity
            let investmentObjectiveInput = self.$refs.investmentObjective.errorMessages.length == 0 && self.$refs.investmentObjective.value != '';
            let timeHorizonInput = self.$refs.timeHorizon.errorMessages.length == 0 && self.$refs.timeHorizon.value != '';
            let riskToleranceInput = self.$refs.riskTolerance.errorMessages.length == 0 && self.$refs.riskTolerance.value != '';
            let courageInput = self.$refs.courage.errorMessages.length == 0 && self.$refs.courage.value != '';
            let investKnowledgeInput = self.$refs.investKnowledge.errorMessages.length == 0 && self.$refs.investKnowledge.value != '';

            self.$emit('is-valid', investmentObjectiveInput && timeHorizonInput && riskToleranceInput 
                                    && courageInput && investKnowledgeInput);
          });
        });
      },
      runValidators(){
        this.$refs.investmentObjective.validate();
        this.$refs.timeHorizon.validate();
        this.$refs.riskTolerance.validate();
        this.$refs.courage.validate();
        this.$refs.investKnowledge.validate();
      }
    },
    setup() {
      const { commonEventHandler } = useCommonEventHandler();
      return { commonEventHandler };
    },
    created() {
      this.clientRiskProfile = this.clientRiskProfileProp;
    },
    mounted() {
      this.isValid();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
