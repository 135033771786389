<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="annualNetIncome"
          label="Annual Net Income *"
          placeholder="Jane"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="clientFinInfo.annualNetIncome"
          >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="netFinancialAssets"
          label="Net Financial Assets *"
          placeholder="Jane"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="clientFinInfo.netFinancialAssets"
          >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="totalNetWorth"
          label="Total Net Worth *"
          placeholder="Jane"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="clientFinInfo.totalNetWorth"
          >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="plannedAgeOfRetirement"
          label="Planned Age of Retirement *"
          placeholder="Jane"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="clientFinInfo.plannedAgeOfRetirement"
          >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6">
        <!-- https://stackoverflow.com/questions/72204997/vuetify-combobox-badges-with-a-removable-button -->
        <v-combobox
          chips
          multiple
          auto-select-first
          closable-chips
          label="Type of Accounts"
          :items="accountTypes"
          v-model="clientFinInfo.selectedAccountTypes">
        </v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="type in clientFinInfo.selectedAccountTypes" :key="type"
        cols="12"
        md="4">
        <!-- https://stackoverflow.com/questions/72204997/vuetify-combobox-badges-with-a-removable-button -->
        <v-card>
          <v-card-item>
            <v-card-title class="text-left">{{ type.title }}</v-card-title>
          </v-card-item>

          <v-card-item>
            <v-text-field
              label="Current Amount Invested *"
              placeholder="Jane"
              :rules="[rules.required]"
              @input="debouncedInputHandler"
              v-model="type.currentAmountInvested">
            </v-text-field>
            <v-text-field
              label="Planned Annual Addition *"
              placeholder="Jane"
              :rules="[rules.required]"
              @input="debouncedInputHandler"
              v-model="type.plannedAnnualAddition">
            </v-text-field>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6">
        <!-- https://stackoverflow.com/questions/72204997/vuetify-combobox-badges-with-a-removable-button -->
        <!-- Not convinced this is the right place for this -->
        <v-combobox
          ref="fundCompanyComparison"
          auto-select-first
          label="Fund Company Comparison"
          :items="competitorTypes"
          v-model="clientFinInfo.fundCompanySelected">
        </v-combobox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useCommonEventHandler } from '../../utilities/useCommonEventHandler.js';
import debounce from 'lodash';
import { nextTick } from 'vue';

export default {
    name: 'ClientFinInfo',
    props: {
      clientFinInfoProp: Object
    },
    data: () => ({
      // clientFinInfo: {
      //   annualNetIncome: null,
      //   netFinancialAssets: null,
      //   totalNetWorth: null,
      //   plannedAgeOfRetirement: null,
      //   selectedAccountTypes: [],
      //   fundCompanyComparison: null
      // },
      clientFinInfo: null,
      accountTypes: [
        { title:'TFSA', currentAmountInvested: null, plannedAnnualAddition: null},
        { title:'RRSP', currentAmountInvested: null, plannedAnnualAddition: null},
        { title:'RIF', currentAmountInvested: null, plannedAnnualAddition: null},
        { title:'Investment', currentAmountInvested: null, plannedAnnualAddition: null},
        { title:'Non-registered', currentAmountInvested: null, plannedAnnualAddition: null}
      ],
      competitorTypes: [
        'Blackrock',
        'FTX',
        'Big Freds Fun Fund',
        'Pharmadrugs Inc Investment Trust'
      ],
      rules: {
        required: value => !!value || 'Field is required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        phoneLength: value => value.length <= 14
      },

    }),
    methods: {
      formatPhoneNumber() {
        //Limit length to max character length for phone number
        if(this.clientDemoInfo.phoneNumber.length > 14){
          this.clientDemoInfo.phoneNumber = this.clientDemoInfo.phoneNumber.substring(0,13)
        }

        // Remove non-numeric characters
        this.clientDemoInfo.phoneNumber = this.clientDemoInfo.phoneNumber.replace(/\D/g, '');
        
        //Format to target pattern
        this.clientDemoInfo.phoneNumber = this.clientDemoInfo.phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '1-$1-$2-$3');
      },
      handleInput() {
        useCommonEventHandler(this.clientDemoInfo);
      },
      debouncedInputHandler() {
        this.isValid();
        debounce(this.handleInput, 500)
      },
      debouncedPhoneInputHandler() {
        this.formatPhoneNumber();
        this.isValid();
        debounce(this.handleInput, 500)
      },
      isValid(){
        let self = this;
        //I dont know why I have to do this but there is a race condition IRT the resolution of the error messages array which causes even next tick to fail
        //and requires a double next tick to get a valid validation state
        nextTick(function(){
          nextTick(function(){
            //Check all of the bound values for validity
            let annualNetIncomeInput = self.$refs.annualNetIncome.errorMessages.length == 0 && self.$refs.annualNetIncome.value != '';
            let netFinancialAssetsInput = self.$refs.netFinancialAssets.errorMessages.length == 0 && self.$refs.netFinancialAssets.value != '';
            let totalNetWorthInput = self.$refs.totalNetWorth.errorMessages.length == 0 && self.$refs.totalNetWorth.value != '';
            let plannedAgeOfRetirementInput = self.$refs.plannedAgeOfRetirement.errorMessages.length == 0 && self.$refs.plannedAgeOfRetirement.value != '';
            let fundCompanyComparisonInput = true; //self.$refs.fundCompanyComparison.errorMessages.length == 0 && self.$refs.fundCompanyComparison.value != '';

            self.$emit('is-valid', annualNetIncomeInput && netFinancialAssetsInput && totalNetWorthInput 
                                    && plannedAgeOfRetirementInput && fundCompanyComparisonInput);
          });
        });
      },
      runValidators(){
        this.$refs.annualNetIncome.validate();
        this.$refs.netFinancialAssets.validate();
        this.$refs.totalNetWorth.validate();
        this.$refs.plannedAgeOfRetirement.validate();
        this.$refs.fundCompanyComparison.validate();
      }
    },
    setup() {
      const { commonEventHandler } = useCommonEventHandler();
      return { commonEventHandler };
    },
    created() {
      this.clientFinInfo = this.clientFinInfoProp;
    },
    mounted() {
      this.isValid();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
