<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Advisor</h2>
        <!-- Note : State 1 is the advisor state -->
        <v-btn height="40vh" color="#5a245b" width="30vw" @click="setState(1)">
          <v-icon size="25vh">mdi-account-tie</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h2>Client</h2>
        <!-- Note : State 2 is the client demographics state -->
        <v-btn height="40vh" color="#5a245b" width="30vw"  @click="setState(2)">
          <v-icon size="25vh">mdi-account</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- <div class="text-center">
      <v-btn
        color="primary"
      >
        Open Dialog

        <v-dialog
          v-model="dialog"
          activator="parent"
          width="500px"
        >
          <v-card style="min-width=400px;">
            <v-card-title>
              <span class="text-h5">Advisor Login</span>
            </v-card-title>
            <v-card-subtitle>
              Please login for access to backoffice.
            </v-card-subtitle>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Password"
                      type="password"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                variant="text"
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="rgb(90, 36, 91)"
                @click="dialog = false"
              >
                Login
              </v-btn>
            </v-card-actions>
            <v-divider></v-divider>
            <h4 class="my-3 ml-3">Don't have an account yet?</h4>
            <v-btn
                color="rgb(90, 36, 91)"
                @click="dialog = false">
              Register
            </v-btn>
          </v-card>
        </v-dialog>
      </v-btn>
    </div> -->
  </v-container>
</template>

<script>
import { useCommonEventHandler } from '../../utilities/useCommonEventHandler.js';

export default {
    name: 'UserPicker',
    props: {
      userPickerInfoProp: Object,
    },
    data: () => ({
      userPickerInfo: null,
      dialog: false
    }),
    methods:{
      setState: function(state){
        useCommonEventHandler(this.userPickerInfo);
        this.$emit('set-state', state);
      }
    },
    setup() {
      const { commonEventHandler } = useCommonEventHandler();
      return { commonEventHandler };
    },
    created() {
      this.userPickerInfo = this.userPickerInfoProp;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
h2 {
  color: gray
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
