<template>
  <div>
    <h1 ref="title">{{ title }}</h1>
    <div v-if="debug">
      {{ debugInfo }}
    </div>
    <p class="mt-3">{{ description }}</p>
    <!-- <keep-alive> -->
      <component :is="dynamicComponent" v-bind="currentProperties" @common-event-handler="commonEventHandler" @set-state="setState" @is-valid="isContinueAvailable"/>
    <!-- </keep-alive> -->
    <v-footer app style="bg-color:transparent;">
      <v-container fluid>
        <v-row style="background: transparent;">
          <v-col class="text-left">
            <v-btn v-if="state != 0" @click="modState(-1)" variant="tonal" class="purpleBtn" style="min-width:125px;">
              Back
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn v-if="state != states.length - 1 && state != 0" :disabled="!isFormValid" @click="modState(1)" variant="tonal" class="purpleBtn" style="min-width:125px;">
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import UserPicker from './UserPicker.vue'
import ClientDemoInfo from './ClientDemoInfo.vue'
import AdvisorInfo from './AdvisorInfo.vue'
import ClientRiskProfile from './ClientRiskProfile.vue'
import ClientFinInfo from './ClientFinInfo.vue'
import PortfolioReport from './PortfolioReport.vue'

const USERTYPES = {
  ADVISOR: 0,
  CLIENT: 1,
  SPOUSE: 2
};

export default {
  name: 'PortfolioGenerator',
  components: {
    UserPicker,
    AdvisorInfo,
    ClientDemoInfo,
    ClientFinInfo,
    ClientRiskProfile,
    PortfolioReport
  },

  props: {
  },
  data: () => ({
    debug: false,
    isFormValid: false,
    userPickerInfo:{
      userType: null
    },
    advisorInfo: {
      firstName: null,
      lastName: null,
      repCode: null,
      dealerCode: null
    },
    clientDemoInfo: {
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      homeAddressOne: null,
      homeAddressTwo: null,
      city: null,
      province: null,
      postalCode: null,
      dob: null
    },
    spouseDemoInfo: null, //Copies empty model of client on mount
    clientFinInfo: {
      annualNetIncome: null,
      netFinancialAssets: null,
      totalNetWorth: null,
      plannedAgeOfRetirement: null,
      selectedAccountTypes: [],
      fundCompanySelected: null
    },
    spouseFinInfo: null, //Copies empty model of client on mount
    clientRiskProfile: {
      investmentObjective: null,
      timeHorizon: null,
      riskTolerance: null,
      courage: null,
      investmentKnowledge: null
    },
    spouseRiskProfile: null, //Copies empty model of client on mount
    portfolioReport: {
      clientRiskScore: null,
      clientRiskLabel: null,
      clientRiskTilt: null,
      fixedIncome: null,
      equity: null,
      fixedIncomePlus: null,
      equityPlus: null
    },
    spousePortfolioReport: null, //Copies empty model of client on mount
    // Default starts on the type selection screen
    state: 0,
    states: [
      "user-picker",//0
      "advisor-info",//1
      "client-demo-info",//2
      "client-fin-info",//3
      "client-risk-profile",//4
      "portfolio-report"//5
    ],
    titles: [
      "Advisor or Client",//0
      "Advisor Info",//1
      "Client Info - Demographics",//2
      "Client Info - Financial",//3
      "Client Risk Profile",//4
      "Report"//5
    ]
  }),
  methods: {
    handleCommonEvent(eventData) {
      switch(this.dynamicComponent){
        case "user-picker":
          this.userPickerInfo = eventData;
          //Check what has been set and swizzle state appropriatelly
          
          return;
        case "advisor-info":
          this.advisorInfo = eventData;
          return;
        case "client-demo-info":
          this.clientDemoInfo = eventData;
          return;
        case "client-fin-info":
          this.clientFinInfo = eventData;
          return;
        case "client-risk-prof":
          this.clientRiskProfile = eventData;
          return;
        case "portfolio-report":
          this.report = eventData;
          return;
        default:
          return;
      }
    },
    modState(stateMod){
      this.isFormValid = false;
      //Don't show the advisor panel when hitting back button if we are a client/spouse and not an advisor
      if((this.userPickerInfo.userType == USERTYPES.CLIENT || this.userPickerInfo.userType == USERTYPES.SPOUSE) && this.state == 2 && stateMod == -1){
        this.setState(0);
      }else{
        this.setState(this.state + stateMod);
      }
    },
    setState(newState){
      console.log("State is : " + this.state);
      //This logic can be set in the component itself following usual bus patterns
      //Instead of relying on this logic
      if(this.state == 0){
        if(newState == 1){
          this.userPickerInfo.userType = USERTYPES.ADVISOR;
        }else if(newState == 2){
          console.log("Type is client");
          this.userPickerInfo.userType = USERTYPES.CLIENT;
        }
      }
      this.state = newState;
      console.log("State is now : " + this.state);
    },
    isContinueAvailable(isValid){
      this.isFormValid =  isValid;
    },
    setDefaultSpouseInfo(){
      this.spouseDemoInfo = this.clientDemoInfo;
      this.spouseFinInfo = this.clientFinInfo;
      this.spouseRiskProfile = this.clientRiskProfile;
      this.spousePortfolioReport = this.portfolioReport;
    }
  },
  watch: {
  },
  computed: {
    debugInfo: function(){
      let returnString = "User Picker Info : " + JSON.stringify(this.userPickerInfo) + "\n";
      returnString = "Advisor Info : " + JSON.stringify(this.advisorInfo) + "\n";
      returnString += "Client Demographic Info : " + JSON.stringify(this.clientDemoInfo) + "\n";
      returnString += "Client Fin Info : " + JSON.stringify(this.clientFinInfo) + "\n";
      returnString += "Client Risk Profile : " + JSON.stringify(this.clientRiskProfile) + "\n";
      returnString += "Portfolio Report : " + JSON.stringify(this.portfolioReport) + "\n";
      return returnString;
    },
    title: function() {
      return this.titles[this.state];
    },
    description: function(){
      let descriptions = [
        "Please select if you are a client or an advisor representing a client.",//0
        "Please fill in your advisor information.",//1
        this.clientDemoInfoInstructions,//2
        this.clientFinInfoInstructions,//3
        this.clientRiskProfileInstructions,//4
        "Based on our calculations we would suggest the listed asset mix :"//5
      ];
      return descriptions[this.state];
    },
    clientDemoInfoInstructions: function(){
      return this.userType == USERTYPES.ADVISOR ? "Please fill in clients demographic information." : "Please fill in your demographic information.";
    },
    clientFinInfoInstructions: function(){
      return this.userType == USERTYPES.ADVISOR ? "Please fill in clients financial informaion." : "Please fill in your financial informaion.";
    },
    clientRiskProfileInstructions: function(){
      return this.userType == USERTYPES.ADVISOR ? "Please fill in client risk profile." : "Please fill in your risk profile.";
    },
    titleWidth: function(){
      return this.$refs.title ? this.$refs.title.offsetWidth : 0;
    },
    dynamicComponent: function() {
      return this.states[this.state];
    },
    currentProperties: function() {
      switch(this.dynamicComponent){
        case "user-picker":
          return { userPickerInfoProp: this.userPickerInfo };
        case "advisor-info":
          //NOTE follow the pattern of adding prop to the end~
          return { advisorInfoProp: this.advisorInfo };
        case "client-demo-info":
          return { clientDemoInfoProp: this.clientDemoInfo };
        case "client-fin-info":
          return { clientFinInfoProp: this.clientFinInfo };
        case "client-risk-profile":
          return { clientRiskProfileProp: this.clientRiskProfile };
        case "portfolio-report":
          return { portfolioReportProps: this.portfolioReport, 
                    clientRiskProfileProp: this.clientRiskProfile, 
                    clientDemoInfoProp: this.clientDemoInfo};
        default:
          return null;
      }
    }
  },
  created() {
    this.setDefaultSpouseInfo();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

h1 {
  color: gray;
  text-decoration: underline #be842d;
  text-underline-offset: 16px;
}

.purpleBtn {
  background-color: #5a245b;
  color: white;
}
</style>
