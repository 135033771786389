<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="firstName"
          label="First Name *"
          placeholder="Jane"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="advisorInfo.firstName"
          >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="lastName"
          label="Lastname *"
          placeholder="Doe"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="advisorInfo.lastName">
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="repCode"
          label="Rep Code *"
          placeholder="Please enter your Rep Code"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="advisorInfo.repCode">
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-text-field
          ref="dealerCode"
          label="Dealer Code *"
          placeholder="Please enter your Dealer Code"
          :rules="[rules.required]"
          @input="debouncedInputHandler"
          v-model="advisorInfo.dealerCode">
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useCommonEventHandler } from '../../utilities/useCommonEventHandler.js';

import debounce from 'lodash';

export default {
    name: 'AdvisorInfo',
    props: {
      advisorInfoProp: Object
    },
    data: () => ({
      advisorInfo: null,
      rules: {
        required: value => !!value || 'Field is required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      isFormValid: false
    }),
    methods: {
      updateState(){
      },
      handleInput() {
        useCommonEventHandler(this.advisorInfo);
      },
      debouncedInputHandler() {
        this.isValid();
        debounce(this.handleInput, 500);
      },
      isValid(){
        //Check all of the bound values for validity
        const firstNameInput = this.$refs.firstName.errorMessages.length == 0 && this.$refs.firstName.value != '';
        const lastNameInput = this.$refs.lastName.errorMessages.length == 0 && this.$refs.lastName.value != '';
        const repCodeInput = this.$refs.repCode.errorMessages.length == 0 && this.$refs.repCode.value != '';
        const dealerCodeInput = this.$refs.dealerCode.errorMessages.length == 0 && this.$refs.dealerCode.value != '';
        this.isFormValid = firstNameInput && lastNameInput && repCodeInput && dealerCodeInput;
        this.$emit('is-valid', this.isFormValid);
      }
    },
    setup() {
      const { commonEventHandler } = useCommonEventHandler();
      return { commonEventHandler };
    },
    created() {
      this.advisorInfo = this.advisorInfoProp;
    },
    mounted() {
      this.isValid();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
