<template>
  <v-app id="yorkville-container" :style="isMobile ? null :  {'margin-top': '-60px !important'}">
    <v-navigation-drawer v-if="!isMobile" v-model="drawer">
      <v-sheet
        color="grey-lighten-4"
        class="pa-4"
      >
        <v-img
          :width="300"
          aspect-ratio="16/9"
          cover
          src="https://www.yorkvilleasset.com/images/logo.png"
        ></v-img>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="[icon, text, stateId, disabled] in links"
          :active="stateId==0"
          :key="icon"
          :prepend-icon="icon"
          :title="text"
          :disabled="disabled"
          link
        ></v-list-item>
      </v-list>

      <v-divider v-if="advisorMode"></v-divider>
      <h4 class="my-3" v-if="advisorMode">Advisor Backoffice</h4>
      <v-divider v-if="advisorMode"></v-divider>

      <v-list v-if="advisorMode">
        <v-list-item
          v-for="[icon, text] in backofficeLinks"
          :active="false"
          :key="icon"
          :prepend-icon="icon"
          :title="text"
          :disabled="false"
          link
        ></v-list-item>
      </v-list>

      <v-divider v-if="advisorMode"></v-divider>
      <h4 class="my-3" v-if="advisorMode">Admin Backoffice</h4>
      <v-divider v-if="advisorMode"></v-divider>

      <v-list v-if="advisorMode">
        <v-list-item
          v-for="[icon, text, stateId, disabled] in adminLinks"
          :active="stateId == 0"
          :key="icon"
          :prepend-icon="icon"
          :title="text"
          :disabled="disabled"
          link
        ></v-list-item>
      </v-list>

    </v-navigation-drawer >
    <!-- TO DO : Mobile bottom nav? -->
    <v-app-bar v-if="isMobile"
      class="px-3"
    >
    <v-img
      max-height="40"
      aspect-ratio="16/9"
      src="https://www.yorkvilleasset.com/images/logo.png">
    </v-img>
    </v-app-bar>
    <v-bottom-navigation v-if="isMobile">
      <v-btn value="recent" style="max-width:100px;">
        <v-icon>mdi-history</v-icon>

        <span>Porfolio Generator</span>
      </v-btn>

      <v-btn value="favorites" style="max-width:100px;" disabled>
        <v-icon>mdi-heart</v-icon>

        <span>Estates & Wills</span>
      </v-btn>

      <v-btn value="nearby" style="max-width:100px;" disabled>
        <v-icon>mdi-map-marker</v-icon>

        <span>Insurance Application</span>
      </v-btn>
    </v-bottom-navigation>

    <v-main :style="isMobile ? {'padding-top': '32px'} :  {'padding-top': '32px'}">
      <portfolio-generator>
      </portfolio-generator>
    </v-main>
  </v-app>
</template>

<script>
import PortfolioGenerator from './components/PortfolioGenerator/PortfolioGenerator.vue'

export default {
  name: 'App',
  components: {
    PortfolioGenerator
  },
  data: () => ({
      advisorMode: false,
      cards: ['Today', 'Yesterday'],
      drawer: null,
      state: 0,
      // https://pictogrammers.com/library/mdi/
      links: [
          ['mdi-inbox-arrow-down', 'Portfolio Generator', 0, false],
          ['mdi-send', 'Estates & Wills', 1, true],
          ['mdi-delete', 'Insurance Application', 2, true]
      ],
      backofficeLinks: [
          ['mdi-inbox-arrow-down', 'Outstanding Proposals', 0, false],
          ['mdi-send', 'Current Clients', 1, false]
      ],
      adminLinks: [
          ['mdi-inbox-arrow-down', 'Advisor Management', 0, false],
      ]
  }),
  computed: {
    isMobile() {
        switch (this.$vuetify.display.name) {
            case 'xs': return true
            case 'sm': return true
            case 'md': return true
            case 'lg': return false
            case 'xl': return false
            default: return false
        }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
