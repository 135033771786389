<template>
  <Pie :data="dataProp" :options="options" />
</template>

<script>
import { Pie } from 'vue-chartjs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: 'PieChart',
  components: { Pie },
  props:{
    dataProp: Array
  },
  data() {
    return {
      pieData: {
        datasets: [
          {
            backgroundColor: ['#5a245b', '#be842d'],
            data: [40, 60]
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true
      }
    }
  }
}
</script>
