<template>
  <v-container>
    <v-row>
      <!-- <v-col
        cols="12"
        md="4">
        Risk Profile : {{ clientRiskLabel }}

        Fixed Income : {{ fixedIncome }}
        Equity : {{ equity }}

        Fixed Income Plus: {{ fixedIncomePlus }}
        Equity Plus: {{ equityPlus }}
        Age : {{ age }}
      </v-col> -->
      <v-col 
        cols="6"
        md="3">
        <h3>Asset Mix</h3>
        <v-card
          class="text-left">
          <template v-slot:text>
            <v-row>
              <v-col cols="8">
                <p>Fixed Income : {{ fixedIncome }}%</p>
                <p>Equity : {{ equity }}%</p>
              </v-col>
              <v-col class="mt-0 pt-0 pb-2" cols="4">
                <PieChart :data-prop="pieData"/>
              </v-col>
            </v-row>
            
          </template>
          
        </v-card>
      </v-col>
      <v-col 
        cols="6"
        md="3">
        <h3>Asset Mix Plus</h3>
        <v-card
          class="text-left">
          <template v-slot:text>
            <v-row>
              <v-col cols="8">
                <p>Fixed Income : {{ fixedIncomePlus }}%</p>
                <p>Equity : {{ equityPlus }}%</p>
              </v-col>
              <v-col class="mt-0 pt-0 pb-2" cols="4">
                <PieChart :data-prop="pieDataPlus"/>
              </v-col>
            </v-row>
            
          </template>
          
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3">
        <h3>Risk Profile</h3>
        <v-card
          class="">
          <template v-slot:title>
            {{ clientRiskLabel }}
            <v-divider></v-divider>
          </template>
          <template v-slot:text>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6">
        <v-card
          class="text-left">
          <template v-slot:title>
            YVT Fund
            <v-divider></v-divider>
          </template>
          <template v-slot:text>
            <v-table>
              <thead>
                <tr>
                  <th class="text-left">
                    Fund
                  </th>
                  <th class="text-left">
                    Category
                  </th>
                  <th class="text-left">
                    Mix
                  </th>
                  <th class="text-left">
                    Mix Plus
                  </th>
                  <th class="text-left">
                    Class
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in yvtFundInfoComputed"
                  :key="item.Fund"
                >
                  <td>{{ item.Fund }}</td>
                  <td>{{ item.Category }}</td>
                  <td>{{ item.Mix }}</td>
                  <td>{{ item.MixPlus }}</td>
                  <td>{{ item.Class }}</td>
                </tr>
              </tbody>
            </v-table>
          </template>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <v-card
          class="text-left">
          <template v-slot:title>
            Comparable Fund
            <v-divider></v-divider>
          </template>
          <template v-slot:text>
            <v-table>
              <thead>
                <tr>
                  <th class="text-left">
                    Fund
                  </th>
                  <th class="text-left">
                    Category
                  </th>
                  <th class="text-left">
                    Mix
                  </th>
                  <th class="text-left">
                    Mix Plus
                  </th>
                  <th class="text-left">
                    Class
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in comparableFundInfo"
                  :key="item.Fund"
                >
                  <td>{{ item.Fund }}</td>
                  <td>{{ item.Category }}</td>
                  <td>{{ item.Mix }}</td>
                  <td>{{ item.MixPlus }}</td>
                  <td>{{ item.Class }}</td>
                </tr>
              </tbody>
            </v-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12">
        <v-card
          class="text-left">
          <template v-slot:title>
            YVT Monthly Return Stream
            <v-divider></v-divider>
          </template>
          <template v-slot:text>
            <v-row no-gutters>
              <v-col
                cols="3"
                md="3">
                <v-table class="custom-table">
                  <thead>
                    <tr>
                      <th class="text-left" style="min-width:375px;" fixed>
                        Fund
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item, key in YVT_FUND_PAST_PERFORMANCE_DATA"
                      :key="key"
                    >
                      <td>{{ key }}</td>
                    </tr>
                    <!-- Hacky way to create a blank row -->
                    <tr>
                      <td v-for="key in [0]" :key="key">{{ }}</td>
                    </tr>
                    <tr>
                      <td>YVT Model Portfolio - {{ clientRiskLabel }}</td>
                    </tr>
                    <tr>
                      <td>YVT Model Model Portfolio - {{ clientRiskLabel + "+" }}</td>
                    </tr>
                    <!-- Hacky way to create a blank row -->
                    <tr>
                      <td v-for="key in [0]" :key="key">{{ }}</td>
                    </tr>
                    <tr>
                      <td>1+ Model Portfolio - {{ clientRiskLabel }}</td>
                    </tr>
                    <tr>
                      <td>1+ Model Portfolio - {{ clientRiskLabel + "+"}}</td>
                    </tr>
                    <!-- Hacky way to create a blank row -->
                    <tr>
                      <td v-for="key in [0]" :key="key">{{ }}</td>
                    </tr>
                    <tr>
                      <td>Growth of $10k : Model Portfolio - {{ clientRiskLabel }}</td>
                    </tr>
                    <tr>
                      <td>Growth of $10K : Model Portfolio - {{ clientRiskLabel + "+"}}</td>
                    </tr>
                  </tbody>
                </v-table>
              </v-col>
              <v-col
                cols="9"
                md="9">
                <v-table>
                  <thead>
                    <tr>
                      <th v-for="item,key in YVT_FUND_PAST_PERFORMANCE_LABELS" :key="key" class="text-left" style="min-width:80px;">
                        {{ item }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item, key in YVT_FUND_PAST_PERFORMANCE_DATA"
                      :key="key"
                    >
                      <td v-for="data, subKey in item" :key="subKey">{{ (data / percentScaler).toFixed(2) + "%" }}</td>
                    </tr>
                    <!-- Hacky way to create a blank row -->
                    <tr>
                      <td v-for="item, key in YVT_FUND_PAST_PERFORMANCE_DATA['Yorkville Optimal Return Bond']" :key="key">{{ }}</td>
                    </tr>
                    <tr>
                      <td v-for="value,key in YVT_FUND_PAST_PERFORMANCE_LABELS" :key="key">{{ modelPerformanceLookup(key) + "%" }}</td>
                    </tr>
                    <tr>
                      <td v-for="value,key in YVT_FUND_PAST_PERFORMANCE_LABELS" :key="key">{{ modelPerformanceLookup(key,true) + "%" }}</td>
                    </tr>
                    <!-- Hacky way to create a blank row -->
                    <tr>
                      <td v-for="item, key in YVT_FUND_PAST_PERFORMANCE_DATA['Yorkville Optimal Return Bond']" :key="key">{{ }}</td>
                    </tr>
                    <tr>
                      <td v-for="value,key in YVT_FUND_PAST_PERFORMANCE_LABELS" :key="key">{{ modelPerformanceLookup(key) + "%" }}</td>
                    </tr>
                    <tr>
                      <td v-for="value,key in YVT_FUND_PAST_PERFORMANCE_LABELS" :key="key">{{ modelPerformanceLookup(key,true) + "%" }}</td>
                    </tr>
                    <!-- Hacky way to create a blank row -->
                    <tr>
                      <td v-for="item, key in YVT_FUND_PAST_PERFORMANCE_DATA['Yorkville Optimal Return Bond']" :key="key">{{ }}</td>
                    </tr>
                    <tr>
                      <td v-for="value,key in growthDataArray" :key="key">${{ value.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td v-for="value,key in growthDataArrayPlus" :key="key">${{ value.toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </v-table>
              </v-col>
            </v-row>
            
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useCommonEventHandler } from '../../utilities/useCommonEventHandler.js';
import PieChart from '../ChartBase/PieChart.vue'

const MAXAGE = 99;
const PLUSMOD = 5;

//Percentages \/\/\/
const EQUITYSPLITS = {
  USA: 0.50,
  CANADA: 0.20,
  INTERNATIONAL: 0.30
};

export default {
    name: 'PortfolioReport',
    components: {
      PieChart
    },
    props: {
      portfolioReportProps: Object,
      clientRiskProfileProp: Object,
      clientDemoInfoProp: Object,
      //NEED TO DETERMINE WHAT ELSE IS NEEDED FOR CALCULATION
    },
    data: () => ({
      percentScaler: 0.01,
      growthDataArray: [],
      growthDataArrayPlus: [],
      comparableFundInfo: [
        {
          Fund: 'Competitor Optimal Return Bond',
          Category: 159,
          Mix: "10%",
          MixPlus: "10%",
          Class: "A"
        },
        {
          Fund: 'Competitor American QVR Enhanced Protection Class',
          Category: 159,
          Mix: "10%",
          MixPlus: "10%",
          Class: "A"
        },
        {
          Fund: 'Competitor Canadian QVR Enhanced Protection Class',
          Category: 159,
          Mix: "10%",
          MixPlus: "10%",
          Class: "A"
        },
        {
          Fund: 'Competitor International QVR Enhanced Protection Class',
          Category: 159,
          Mix: "10%",
          MixPlus: "10%",
          Class: "A"
        },
      ],
      userProfileMissingError: "Error - Please fill in user profile.",
      // portfolioReport: {
      //   clientRiskScore: null,
      //   clientRiskLabel: null,
      //   clientRiskTilt: null,
      //   fixedIncome: null,
      //   equity: null,
      //   fixedIncomePlus: null,
      //   equityPlus: null
      // },
      portfolioReport: null,
      clientRiskTolerance: null,
      balancedFixedIncomeLookup:{
        20: 20,
        21: 20,
        22: 20,
        23: 20,
        24: 20,
        25: 25,
        26: 25,
        27: 25,
        28: 25,
        29: 25,
        30: 30,
        31: 30,
        32: 30,
        33: 30,
        34: 30,
        35: 35,
        36: 35,
        37: 35,
        38: 35,
        39: 35,
        40: 40,
        41: 40,
        42: 40,
        43: 40,
        44: 40,
        45: 45,
        46: 45,
        47: 45,
        48: 45,
        49: 45,
        50: 50,
        51: 50,
        52: 50,
        53: 50,
        54: 50,
        55: 55,
        56: 55,
        57: 55,
        58: 55,
        59: 55,
        60: 60,
        61: 60,
        62: 60,
        63: 60,
        64: 60,
        65: 65,
        66: 65,
        67: 65,
        68: 65,
        69: 65,
        70: 70,
        71: 70,
        72: 70,
        73: 70,
        74: 70,
        75: 75,
        76: 75,
        77: 75,
        78: 75,
        79: 75,
        80: 80,
        81: 80,
        82: 80,
        83: 80,
        84: 80,
        85: 85,
        86: 85,
        87: 85,
        88: 85,
        89: 85,
        90: 90,
        91: 90,
        92: 90,
        93: 90,
        94: 90,
        95: 95,
        96: 95,
        97: 95,
        98: 95,
        99: 95
      },
      clientRiskTiltLookup:{
        "Conservative": 10,
        "Balanced": 0,
        "Growth": -10,
      },
      YVT_FUND_PAST_PERFORMANCE_DATA: {
        'Yorkville Optimal Return Bond': [0.0220110900,0.0075391030,-0.0010948440,-0.0009297474,-0.0106226000,-0.0244251200,-0.0077602620,-0.0045503740,0.0079678110,0.0091645600,0.0123581000,0.0079377930,-0.0077835290,-0.0194034100,0.0138621500,-0.0122309500,0.0145402900,0.0074405220,-0.0025565430,-0.0067348620,0.0015033400,0.0197849000,-0.0081033710,0.0096000120,0.0053036730,-0.0068207170,0.0070193280,-0.0098339160,-0.0051218040,0.0116885700,0.0181620200,0.0109241300,-0.0008741837,0.0247117800,-0.0031000000,0.0191000000,0.0061000000,0.0003000000,0.0244000000,-0.0119000000,-0.0027000000,0.0011000000,-0.0154000000,0.0329000000,0.0110000000,0.0163000000,0.0302000000,0.0012000000,0.0116000000,0.0100000000,-0.0116300000,0.0035000000,-0.0071000000,0.0060000000,0.0022000000,-0.0117000000,-0.0280000000,-0.0115000000,0.0014000000,0.0054000000,0.0073000000,0.0104000000,-0.0011000000,-0.0148000000,-0.0128000000,0.0095000000,0.0167000000,-0.0333000000,-0.0058000000,-0.0314000000,-0.0359000000,-0.0012000000,-0.0249000000,0.0396000000,-0.0293000000,-0.0042000000,-0.0059000000,0.0191000000,-0.0109000000],
        'Yorkville American QVR Enhanced Protection Class': [-0.0003465993,0.0162754200,0.0100945200,-0.0029106730,0.0026302420,0.0247230100,0.0155154500,-0.0123659600,0.0295471200,-0.0062356790,0.0471177500,0.0049185060,-0.0317913600,-0.0213243600,0.0052126770,-0.0070147650,0.0403254900,0.0119746300,-0.0164687000,0.0543782300,0.0018872680,-0.0168839700,0.0026489370,0.0317384800,0.0154749600,0.0104228000,0.0237584200,-0.0064193200,-0.0577709400,0.0337030900,-0.0193123100,0.0113104200,0.0160774200,0.0209057200,0.0289000000,-0.0310000000,-0.0080000000,0.0140000000,0.0022000000,-0.0098000000,-0.0033000000,0.0484000000,0.0066000000,0.0079000000,-0.0427000000,-0.0507000000,0.0728000000,0.0373000000,-0.0037000000,0.0521000000,0.0472000000,-0.0080000000,-0.0322000000,0.0900000000,0.0256000000,0.0404000000,-0.0199000000,-0.0259000000,0.0311000000,-0.0243000000,0.0643000000,0.0067000000,0.0475000000,-0.0411000000,0.0322000000,0.0106000000,-0.0038000000,-0.0553000000,-0.0223000000,0.0083000000,-0.0521000000,-0.0055000000,-0.0695000000,0.0473000000,-0.0084000000,-0.0107000000,0.0655000000,0.0271000000,-0.0482000000],
        'Yorkville Canadian QVR Enhanced Protection Class': [-0.0175896300,0.0266094400,0.0134074400,0.0067654790,0.0022946390,0.0108986300,0.0161859700,0.0019570750,0.0181680500,0.0096929650,0.0090271910,-0.0037569600,-0.0033189540,-0.0146810600,0.0054423900,0.0271928800,0.0302803300,-0.0061965590,-0.0001567507,0.0070374690,-0.0227292400,-0.0154019900,0.0184398500,0.0130388400,0.0025634840,0.0107177200,-0.0040988300,-0.0013010920,-0.0489741700,-0.0005964085,-0.0243927500,0.0249739600,0.0161783600,0.0049663320,0.0158000000,-0.0151000000,0.0103000000,0.0013000000,-0.0128000000,0.0144000000,-0.0165000000,0.0317000000,-0.0124000000,0.0287000000,-0.0426000000,-0.1026000000,0.0377000000,0.0379000000,0.0074000000,0.0466000000,0.0126000000,-0.0111000000,-0.0376000000,0.0638000000,0.0270000000,-0.0065000000,0.0717000000,0.0218000000,0.0244000000,0.0114000000,0.0277000000,-0.0036000000,0.0242000000,-0.0112000000,0.0393000000,-0.0265000000,0.0189000000,0.0288000000,-0.0002000000,0.0188000000,-0.0442000000,0.0036000000,-0.0786000000,0.0247000000,0.0029000000,-0.0205000000,0.0366000000,0.0275000000,-0.0371000000],
        'Yorkville International QVR Enhanced Protection Class': [0.0000000000,0.0000000000,-0.0002900000,0.0020405920,0.0019965060,-0.0042241590,0.0051125050,-0.0002588043,0.0143076200,0.0196617400,0.0435037900,0.0289958000,-0.0308146100,-0.0180016800,0.0105506100,0.0114378900,0.0360822800,0.0020281630,-0.0149496200,0.0329124600,-0.0116827900,-0.0122654500,0.0011983440,0.0074940820,0.0069241270,0.0184459600,-0.0114982300,0.0034546090,-0.0464984500,0.0084003660,-0.0149113000,0.0066805310,0.0083272400,0.0343030200,0.0111000000,-0.0476000000,0.0062000000,-0.0025000000,0.0034000000,0.0033000000,-0.0083000000,0.0244000000,0.0049000000,-0.0019000000,-0.0515000000,-0.0601000000,0.0222000000,0.0330000000,0.0257000000,0.0401000000,0.0186000000,-0.0097000000,-0.0340000000,0.0609000000,0.0340000000,0.0219000000,0.0037000000,-0.0095000000,0.0090000000,0.0012000000,0.0110000000,-0.0135000000,0.0314000000,-0.0563000000,0.0285000000,-0.0014000000,0.0056000000,-0.0247000000,-0.0576000000,-0.0209000000,-0.0425000000,-0.0072000000,-0.0444000000,0.0094000000,-0.0167000000,-0.0137000000,0.0454000000,0.0663000000,-0.0176000000]
      },
      YVT_FUND_PAST_PERFORMANCE_LABELS: ["Jun-16","Jul-16","Aug-16","Sep-16","Oct-16","Nov-16","Dec-16","Jan-17","Feb-17","Mar-17","Apr-17","May-17","Jun-17","Jul-17","Aug-17","Sep-17","Oct-17","Nov-17","Dec-17","Jan-18","Feb-18","Mar-18","Apr-18","May-18","Jun-18","Jul-18","Aug-18","Sep-18","Oct-18","Nov-18","Dec-18","Jan-19","Feb-19","Mar-19","Apr-19","May-19","Jun-19","Jul-19","Aug-19","Sep-19","Oct-19","Nov-19","Dec-19","Jan-20","Feb-20","Mar-20","Apr-20","May-20","Jun-20","Jul-20","Aug-20","Sep-20","Oct-20","Nov-20","Dec-20","Jan-21","Feb-21","Mar-21","Apr-21","May-21","Jun-21","Jul-21","Aug-21","Sep-21","Oct-21","Nov-21","Dec-21","Jan-22","Feb-22","Mar-22","Apr-22","May-22","Jun-22","Jul-22","Aug-22","Sep-22","Oct-22","Nov-22","Dec-22"]
    }),
    methods: {
      calculateReport: function(){
        if(this.clientRiskProfileProp != null){
          this.portfolioReport.clientRiskScore = 
            this.clientRiskProfileProp.investmentObjective.value + 
            this.clientRiskProfileProp.timeHorizon.value +
            this.clientRiskProfileProp.riskTolerance.value +
            this.clientRiskProfileProp.courage.value +
            this.clientRiskProfileProp.investmentKnowledge.value;

          this.portfolioReport.clientRiskLabel = this.clientRiskLabel;
          this.portfolioReport.clientRiskTilt = this.clientRiskTilt;
          this.portfolioReport.fixedIncome = this.fixedIncome;
          this.portfolioReport.equity = this.equity;
          this.portfolioReport.fixedIncomePlus = this.fixedIncomePlus;
          this.portfolioReport.equityPlus = this.equityPlus;
          useCommonEventHandler(this.portfolioReport);
        }else{
          //Should never be hit if you have hit this something is broken
          return this.userProfileMissingError
        }
      },
      modelPerformanceLookup: function(index, isPlus=false){
        //Get the product of all of the calculated model outcomes x performance data
        let resultant = 0;
        const model_performance_link = {
          'Yorkville Optimal Return Bond': isPlus ? this.fixedIncomePlus : this.fixedIncome,
          'Yorkville American QVR Enhanced Protection Class': isPlus ? this.equitySplitUSAPlus : this.equitySplitUSA,
          'Yorkville Canadian QVR Enhanced Protection Class': isPlus ? this.equitySplitCanadaPlus : this.equitySplitCanada,
          'Yorkville International QVR Enhanced Protection Class': isPlus ? this.equitySplitIntPlus : this.equitySplitInt
        };
        Object.keys(this.YVT_FUND_PAST_PERFORMANCE_DATA).forEach((key)=>{
          resultant += (model_performance_link[key] * this.percentScaler) * this.YVT_FUND_PAST_PERFORMANCE_DATA[key][index];
        });

        //Turn display percent values into actual percentage values
        return (resultant / this.percentScaler).toFixed(2);
      },
      generateRollingGrowthArrays(){
        this.growthDataArray = [];
        this.growthDataArrayPlus = [];
        for(let i = 0; i < this.YVT_FUND_PAST_PERFORMANCE_LABELS.length; i++){
          //Get model peformance and get the result of multiplying last net value by modelPerformanceLookup
          let currentWealth = i == 0 ? 10000 : this.growthDataArray[i-1];
          let currentWealthPlus = i == 0 ? 10000 : this.growthDataArrayPlus[i-1]; 
          this.growthDataArray.push((currentWealth * (1+this.modelPerformanceLookup(i) * this.percentScaler)));
          this.growthDataArrayPlus.push((currentWealthPlus * (1+this.modelPerformanceLookup(i, true) * this.percentScaler)));
        }
      }
    },
    computed: {
      age: function(){
        //TO DO TAKE THE DOB VALUE AND TURN IT INTO AN AGE
        if(this.clientDemoInfoProp != null){
          return Math.floor(((new Date()).getTime() - (new Date(this.clientDemoInfoProp.dob)).getTime()) / (1000 * 60 * 60 * 24 * 365));
        }else{
          //Should never be hit if you have hit this something is broken
          return this.userProfileMissingError
        }
      },
      clientRiskLabel: function(){
        if(this.portfolioReport.clientRiskScore != null){
          if(this.portfolioReport.clientRiskScore < 8){
            return "Conservative";
          }else if(this.portfolioReport.clientRiskScore >= 8 && this.portfolioReport.clientRiskScore <= 16){
            return "Balanced";
          }else{
            return "Growth";
          }
        }else{
          //Should never be hit if you have hit this something is broken
          return this.userProfileMissingError
        }
      },
      clientRiskTilt: function(){
        if(this.clientRiskLabel != this.userProfileMissingError){
          return this.clientRiskTiltLookup[this.clientRiskLabel]
        }else{
          //Should never be hit if you have hit this something is broken
          return this.userProfileMissingError
        }
      },
      fixedIncome: function(){
        if(this.age > MAXAGE){
          //Max of balanced values
          return this.balancedFixedIncomeLookup[this.balancedFixedIncomeLookup.length - 1];
        }else{
          //Balanced Value + Risk Tilt
          return this.balancedFixedIncomeLookup[this.age] + this.clientRiskTilt;
        }
      },
      fixedIncomePlus: function(){
        return this.fixedIncome - PLUSMOD;
      },
      equity: function(){
        return 100 - this.fixedIncome;
      },
      equityPlus: function(){
        return this.equity + PLUSMOD;
      },
      yvtFundInfoComputed: function(){
        const yvtFundInfo = [
          {
            Fund: 'Yorkville Optimal Return Bond',
            Category: "Core Fixed Income",
            Mix: this.fixedIncome + "%",
            MixPlus: this.fixedIncomePlus + "%",
            Class: "A"
          },
          {
            Fund: 'Yorkville American QVR Enhanced Protection Class',
            Category: "US Equity",
            Mix: this.equitySplitUSA + "%",
            MixPlus: this.equitySplitUSAPlus + "%",
            Class: "A"
          },
          {
            Fund: 'Yorkville Canadian QVR Enhanced Protection Class',
            Category: "Canadian Equity",
            Mix: this.equitySplitCanada + "%",
            MixPlus: this.equitySplitCanadaPlus + "%",
            Class: "A"
          },
          {
            Fund: 'Yorkville International QVR Enhanced Protection Class',
            Category: "International Equity",
            Mix: this.equitySplitInt + "%",
            MixPlus: this.equitySplitIntPlus + "%",
            Class: "A"
          },
        ]
        return yvtFundInfo;
      },
      equitySplitUSA: function(){
        return this.equity * EQUITYSPLITS.USA;
      },
      equitySplitCanada: function(){
        return this.equity * EQUITYSPLITS.CANADA
      },
      equitySplitInt: function(){
        return this.equity * EQUITYSPLITS.INTERNATIONAL;
      },
      equitySplitUSAPlus: function(){
        return this.equityPlus * EQUITYSPLITS.USA;
      },
      equitySplitCanadaPlus: function(){
        return this.equityPlus * EQUITYSPLITS.CANADA
      },
      equitySplitIntPlus: function(){
        return this.equityPlus * EQUITYSPLITS.INTERNATIONAL;
      },
      pieData: function(){
        return {
          datasets: [
            {
              backgroundColor: ['#5a245b', '#be842d'],
              data: [this.fixedIncome, this.equity]
            }
          ]
        };
      },
      pieDataPlus: function(){
        return {
          datasets: [
            {
              backgroundColor: ['#5a245b', '#be842d'],
              data: [this.fixedIncomePlus, this.equityPlus]
            }
          ]
        };
      }
    },
    setup() {
      const { commonEventHandler } = useCommonEventHandler();
      return { commonEventHandler };
    },
    created() {
      this.portfolioReport = this.portfolioReportProps;
      this.calculateReport();
      this.generateRollingGrowthArrays();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0 8px; /* Adjust the spacing as needed */
}

.custom-table td {
  border-right: 1px solid #ccc; /* Adjust the color and width as needed */
  border-bottom: 1px solid #ccc; /* Adjust the color and width as needed */
}
</style>
